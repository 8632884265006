/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./tachyons/css/tachyons.css"
import "./src/styles/index"

export const onServiceWorkerUpdateReady = () => window.location.reload(true)
export const registerServiceWorker = () => true
