// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-alterar-senha-index-js": () => import("./../../../src/pages/admin/alterar-senha/index.js" /* webpackChunkName: "component---src-pages-admin-alterar-senha-index-js" */),
  "component---src-pages-admin-assinantes-editar-index-js": () => import("./../../../src/pages/admin/assinantes/editar/index.js" /* webpackChunkName: "component---src-pages-admin-assinantes-editar-index-js" */),
  "component---src-pages-admin-assinantes-index-js": () => import("./../../../src/pages/admin/assinantes/index.js" /* webpackChunkName: "component---src-pages-admin-assinantes-index-js" */),
  "component---src-pages-admin-banners-ensaios-cadastrar-index-js": () => import("./../../../src/pages/admin/banners-ensaios/cadastrar/index.js" /* webpackChunkName: "component---src-pages-admin-banners-ensaios-cadastrar-index-js" */),
  "component---src-pages-admin-banners-ensaios-index-js": () => import("./../../../src/pages/admin/banners-ensaios/index.js" /* webpackChunkName: "component---src-pages-admin-banners-ensaios-index-js" */),
  "component---src-pages-admin-banners-gerais-cadastrar-index-js": () => import("./../../../src/pages/admin/banners-gerais/cadastrar/index.js" /* webpackChunkName: "component---src-pages-admin-banners-gerais-cadastrar-index-js" */),
  "component---src-pages-admin-banners-gerais-index-js": () => import("./../../../src/pages/admin/banners-gerais/index.js" /* webpackChunkName: "component---src-pages-admin-banners-gerais-index-js" */),
  "component---src-pages-admin-criadores-index-js": () => import("./../../../src/pages/admin/criadores/index.js" /* webpackChunkName: "component---src-pages-admin-criadores-index-js" */),
  "component---src-pages-admin-ensaios-criar-index-js": () => import("./../../../src/pages/admin/ensaios/criar/index.js" /* webpackChunkName: "component---src-pages-admin-ensaios-criar-index-js" */),
  "component---src-pages-admin-ensaios-index-js": () => import("./../../../src/pages/admin/ensaios/index.js" /* webpackChunkName: "component---src-pages-admin-ensaios-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-postagens-index-js": () => import("./../../../src/pages/admin/postagens/index.js" /* webpackChunkName: "component---src-pages-admin-postagens-index-js" */),
  "component---src-pages-admin-usuarios-assinantes-index-js": () => import("./../../../src/pages/admin/usuarios/assinantes/index.js" /* webpackChunkName: "component---src-pages-admin-usuarios-assinantes-index-js" */),
  "component---src-pages-boleto-boleto-expirado-index-js": () => import("./../../../src/pages/boleto/boleto-expirado/index.js" /* webpackChunkName: "component---src-pages-boleto-boleto-expirado-index-js" */),
  "component---src-pages-boleto-index-js": () => import("./../../../src/pages/boleto/index.js" /* webpackChunkName: "component---src-pages-boleto-index-js" */),
  "component---src-pages-boleto-novo-boleto-index-js": () => import("./../../../src/pages/boleto/novo-boleto/index.js" /* webpackChunkName: "component---src-pages-boleto-novo-boleto-index-js" */),
  "component---src-pages-cadastro-assinante-index-js": () => import("./../../../src/pages/cadastro-assinante/index.js" /* webpackChunkName: "component---src-pages-cadastro-assinante-index-js" */),
  "component---src-pages-cadastro-criador-index-js": () => import("./../../../src/pages/cadastro-criador/index.js" /* webpackChunkName: "component---src-pages-cadastro-criador-index-js" */),
  "component---src-pages-conta-assinante-index-js": () => import("./../../../src/pages/conta-assinante/index.js" /* webpackChunkName: "component---src-pages-conta-assinante-index-js" */),
  "component---src-pages-conta-criador-index-js": () => import("./../../../src/pages/conta-criador/index.js" /* webpackChunkName: "component---src-pages-conta-criador-index-js" */),
  "component---src-pages-criadores-index-js": () => import("./../../../src/pages/criadores/index.js" /* webpackChunkName: "component---src-pages-criadores-index-js" */),
  "component---src-pages-ensaios-detalhes-index-js": () => import("./../../../src/pages/ensaios/detalhes/index.js" /* webpackChunkName: "component---src-pages-ensaios-detalhes-index-js" */),
  "component---src-pages-ensaios-index-js": () => import("./../../../src/pages/ensaios/index.js" /* webpackChunkName: "component---src-pages-ensaios-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-favoritos-index-js": () => import("./../../../src/pages/favoritos/index.js" /* webpackChunkName: "component---src-pages-favoritos-index-js" */),
  "component---src-pages-feed-index-js": () => import("./../../../src/pages/feed/index.js" /* webpackChunkName: "component---src-pages-feed-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-minha-conta-alterar-email-publico-index-js": () => import("./../../../src/pages/minha-conta/alterar-email-publico/index.js" /* webpackChunkName: "component---src-pages-minha-conta-alterar-email-publico-index-js" */),
  "component---src-pages-minha-conta-esqueci-minha-senha-index-js": () => import("./../../../src/pages/minha-conta/esqueci-minha-senha/index.js" /* webpackChunkName: "component---src-pages-minha-conta-esqueci-minha-senha-index-js" */),
  "component---src-pages-minha-conta-index-js": () => import("./../../../src/pages/minha-conta/index.js" /* webpackChunkName: "component---src-pages-minha-conta-index-js" */),
  "component---src-pages-minha-conta-trocar-senha-index-js": () => import("./../../../src/pages/minha-conta/trocar-senha/index.js" /* webpackChunkName: "component---src-pages-minha-conta-trocar-senha-index-js" */),
  "component---src-pages-notificacoes-index-js": () => import("./../../../src/pages/notificacoes/index.js" /* webpackChunkName: "component---src-pages-notificacoes-index-js" */),
  "component---src-pages-p-index-js": () => import("./../../../src/pages/p/index.js" /* webpackChunkName: "component---src-pages-p-index-js" */),
  "component---src-pages-perfil-index-js": () => import("./../../../src/pages/perfil/index.js" /* webpackChunkName: "component---src-pages-perfil-index-js" */),
  "component---src-pages-perfil-nao-assinante-index-js": () => import("./../../../src/pages/perfil/nao-assinante/index.js" /* webpackChunkName: "component---src-pages-perfil-nao-assinante-index-js" */),
  "component---src-pages-pix-components-selected-plan-card-js": () => import("./../../../src/pages/pix/components/SelectedPlanCard.js" /* webpackChunkName: "component---src-pages-pix-components-selected-plan-card-js" */),
  "component---src-pages-pix-index-js": () => import("./../../../src/pages/pix/index.js" /* webpackChunkName: "component---src-pages-pix-index-js" */),
  "component---src-pages-planos-index-js": () => import("./../../../src/pages/planos/index.js" /* webpackChunkName: "component---src-pages-planos-index-js" */),
  "component---src-pages-post-index-js": () => import("./../../../src/pages/post/index.js" /* webpackChunkName: "component---src-pages-post-index-js" */),
  "component---src-pages-privacidade-index-js": () => import("./../../../src/pages/privacidade/index.js" /* webpackChunkName: "component---src-pages-privacidade-index-js" */),
  "component---src-pages-termos-index-js": () => import("./../../../src/pages/termos/index.js" /* webpackChunkName: "component---src-pages-termos-index-js" */)
}

